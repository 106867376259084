<template>
  <v-container fluid class="px-8">
    <v-toolbar height="50" class="mb-4 mt-2" elevation="4" rounded v-show="this.$route.name != 'roles'">
      <ConfigMenu />
    </v-toolbar>
    <router-view></router-view>
  </v-container>
</template>

<script>
import ConfigMenu from './Menu.vue';

export default {
  components: { ConfigMenu },
};
</script>

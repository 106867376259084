<template>
  <v-toolbar-items>
    <sub-menu name="Settings Menu" :menu-items="pages" @sub-menu-click="onMenuItemClick" showDropDown />
    <v-btn
      v-if="isAnyAdmin"
      v-show="currentPage == 'trip-request/chaperone-rules'"
      text
      class="nav-button primary white--text"
      @click="addChaperoneRule"
    >
      Add Chaperone Rule
    </v-btn>
    <v-btn
      v-if="isAnyAdmin"
      v-show="currentPage == 'budget-code'"
      text
      class="nav-button primary white--text"
      @click="addBudgetCodeSection"
      :disabled="hasExisingBudgetCodes"
    >
      Add Section
    </v-btn>
    <v-btn
      v-if="isAnyAdmin"
      v-show="showSave"
      :disabled="currentPage == 'budget-code' && hasExisingBudgetCodes"
      text
      class="nav-button success white--text"
      @click="save"
    >
      <v-icon left class="white--text">mdi-content-save</v-icon>
      Save
    </v-btn>
  </v-toolbar-items>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SubMenu from '@/components/SubMenu.vue';
import { showDevFeatures } from '@/util';

const saveFunctions = {
  'trip-request/labels': 'saveLabelsConfig',
  'trip-request/messages': 'saveMessagesConfig',
  'trip-request/display-hide': 'saveDisplayHideConfig',
  'trip-request/chaperone-rules': 'saveChaperoneRulesConfig',
  'trip-request/trip-ticket-permission-slip': 'saveTemplatesConfig',
  'trip-request/trip-estimator': 'saveTripEstimateConfig',
  'trip-request/other': 'saveOtherConfig',
  'budget-code': 'saveBudgetCode',
  calendars: 'saveCalendarConfig',
  notifications: 'saveNotifications',
  driver: 'saveDriverConfig',
  'vehicle-owners': 'saveVehicleOwnerConfig',
  invoice: 'saveInvoiceConfig',
};

export default {
  name: 'ConfigMenu',
  inject: ['eventHub'],
  components: { SubMenu },
  data() {
    return {
      currentPage: '',
    };
  },
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('budgetCode', ['hasExisingBudgetCodes']),
    isAnyAdmin() {
      return (
        this.me.is.superAdmin ||
        this.me.is.transportationAdmin ||
        (this.me.is.limitedAdmin && this.me.data.limitedAdmin.settings)
      );
    },
    isShowInvoice() {
      return this.isAnyAdmin;
    },
    pages() {
      const items = [
        {
          name: 'Approval Levels',
          page: 'approval-levels',
          show: this.me.is.superAdmin || this.me.is.transportationAdmin,
        },
        {
          name: 'School Years / Sessions',
          page: 'semesters',
          show: this.isAnyAdmin,
        },
        {
          name: 'Fiscal Years',
          page: 'fiscal-years',
          show: this.isAnyAdmin,
        },
        {
          name: 'Budget Code Structure',
          page: 'budget-code',
          show: this.me.is.superAdmin,
        },
        {
          name: 'Calendars',
          page: 'calendars',
          show: this.me.is.superAdmin || this.me.is.transportationAdmin,
        },
        {
          name: 'Notifications',
          page: 'notifications',
          show: this.me.is.superAdmin || this.me.is.transportationAdmin,
        },
        {
          name: 'Drivers',
          page: 'driver',
          show: this.isAnyAdmin,
        },
        {
          name: 'Vehicle Owners',
          page: 'vehicle-owners',
          show: this.isAnyAdmin,
        },
        {
          name: 'Trip Request Form',
          show: this.isAnyAdmin,
          menu: [
            {
              name: 'Labels',
              page: 'trip-request/labels',
            },
            {
              name: 'Messages',
              page: 'trip-request/messages',
            },
            {
              name: 'Display / Hide',
              page: 'trip-request/display-hide',
            },
            {
              name: 'Custom Form Fields',
              page: 'trip-request/custom-form-fields',
            },
            {
              name: 'Dates & Times',
              page: 'trip-request/dates-times',
            },
            {
              name: 'Chaperone Rules',
              page: 'trip-request/chaperone-rules',
            },
            {
              name: 'Trip Ticket / Permission Slip',
              page: 'trip-request/trip-ticket-permission-slip',
            },
            {
              name: 'Trip Estimator',
              page: 'trip-request/trip-estimator',
            },
            {
              name: 'Other',
              page: 'trip-request/other',
            },
          ],
        },
        {
          name: 'Invoice',
          show: this.isShowInvoice,
          menu: [
            {
              name: 'Invoice',
              page: 'invoice',
            },
            {
              name: 'Custom Form Fields',
              page: 'invoice/invoice-custom-form-fields',
            },
          ],
        },
      ];
      return items.filter((e) => e.show);
    },
    menuLabel() {
      // const current = this.pages.concat(this.pages[this.pages.length - 1].menu).find((e) => e.page == this.currentPage);
      const pageLabel = this.currentPage.includes('trip-request/') ? this.currentPage.split('/')[1] : this.currentPage;
      return pageLabel;
    },
    showSave() {
      return (
        this.currentPage == 'trip-request/labels' ||
        this.currentPage == 'trip-request/messages' ||
        this.currentPage == 'trip-request/display-hide' ||
        this.currentPage == 'trip-request/chaperone-rules' ||
        this.currentPage == 'trip-request/trip-ticket-permission-slip' ||
        this.currentPage == 'trip-request/other' ||
        this.currentPage == 'budget-code' ||
        this.currentPage == 'calendars' ||
        this.currentPage == 'notifications' ||
        this.currentPage == 'driver' ||
        this.currentPage == 'vehicle-owners' ||
        this.currentPage == 'invoice'
      );
    },
  },
  created() {
    this.currentPage = this.$route.name;
  },
  methods: {
    ...mapActions('budgetCode', ['getBudgetCodes']),
    onMenuItemClick(item) {
      this.$router.push(`/config/${item.page}`);
    },
    save() {
      this.eventHub.$emit(saveFunctions[this.currentPage]);
    },
    addChaperoneRule() {
      this.eventHub.$emit('addChaperoneRule');
    },
    addBudgetCodeSection() {
      this.eventHub.$emit('addBudgetCodeSection');
    },
  },
  watch: {
    $route(to, from) {
      this.currentPage = to.name;
    },
  },
  async mounted() {
    await this.getBudgetCodes();
  },
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
.search-field {
  max-width: 267px;
}
</style>
